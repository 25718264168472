<template>
	<div style="padding: 20px;text-align: center;" v-loading="loading">
		<!-- <span id="LoginWithAmazon" @click="LoginWithAmazon">
			<img border="0" alt="Login with Amazon" src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" width="156" height="32" />
		</span>
		<div id="amazon-root"></div> -->
	
		<div style="padding: 20px 10px; font-size: 16px;">
	
			<div class="step-con">
				<div class="step-title">STEP2</div>
	
				<div class="step-msg">授权回调页面...</div>
				
				<div class="step-msg">
					<strong style="color:red;">{{msg}}</strong>
				</div>
	
				<!-- <div class="step-loading-icon" style=""><i class="el-icon-loading"></i></div> -->
	
				<div class="auth-icon-con">
					<div class="auth-icon-az">
						<img src="../../assets/images/ups/UPS_logo.svg" width="50" height="auto" />
					</div>
					<div class="auth-icon-arrow">
						<img src="../../assets/images/amazon/arrow-bothway.png" width="30" height="auto" />
					</div>
					<div class="auth-icon-hytx">
						<img :src="$store.state.sysShow.logoInfo.logo" width="150" height="auto" />
					</div>
				</div>
	
				<!-- <a target="_blank" :href="authSrc">{{$t('i18nn_907aa9302190b8b6')}}</a> -->
			</div>
			<div style="padding: 20px;">
				<el-button type="primary" @click="closePage()">{{$t('i18nn_b9f099bf21b81e70')}}</el-button>
				<!-- 回调页面
				<br />
				code:
				<br />
				{{code}} -->
			</div>
		</div>
	</div>
	
	
</template>

<script>
	export default {
		data() {
			return {
				code:"",
				loading:"",
				msg:"",
			}
		},
		created() {
		  
		},
		mounted() {
		  console.log(this.$route.query);
			
			// console.log(this.$route.query.code);
			this.code = decodeURIComponent(this.$route.query.code);
			console.log(this.code);
			this.postUpsCode();
		},
		methods: {
			// toAuth(){
			// 	let  testClientID = "";
			// 	let  redirect_uri = "";
			// 	window.location.href=`https://wwwcie.ups.com/security/v1/oauth/validate-client?client_id=${testClientID}&redirect_uri=${redirect_uri}`
			// }
			closePage(){
				window.open("about:blank","_self").close()
			},
			//传递code
			postUpsCode(){
				this.loading = true;
				
				this.$http
					// .get(this.$urlConfig.upsAuthPostCode+'?code='+encodeURIComponent(this.code), {})
					.put(this.$urlConfig.upsAuthPostCode, {"code":this.code})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'), data);
						// console.log(data);
						
						this.loading = false;
						if ('200' == data.code) {
							// this.ClientID = data.data.clientId;
							// this.$message.success("提交Code成功！");
							
							this.msg = '验证Code成功,您可以关闭本页面';
							this.$alert(this.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'success'
							});
						} else {
							if (!data.msg) {
								data.msg = '验证code失败';
							}
							// this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
							// 	type: 'warning'
							// });
							this.msg = data.msg;
						}
					})
					.catch(error => {
						// console.log(error);
						console.log(this.$t('tips.submitError'), error);
						this.loading = false;
						
						this.msg = '验证code失败！';
						this.$alert(this.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
		}
	}
</script>

<style lang="less" scoped>
	.step-con {
		line-height: 150%;
	}
	.step-title {
		font-size: 32px; 
		padding: 10px 0;
	}
	.step-loading-icon {
		font-size: 24px; 
		padding: 10px 0;
	}
	.step-msg {
		font-size: 18px; 
		padding: 10px 0;
	}
	.auth-icon-con {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		.auth-icon-az {
			padding: 0 20px;
		}
		.auth-icon-arrow {
			padding: 0 20px;
		}
		.auth-icon-hytx {
			padding: 0 20px;
		}
	}
</style>